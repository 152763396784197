import React, { FC } from 'react';
import LoginForm from './components/login-form.component';
import SorensonLogin from 'shared/assets/images/sorenson-login-new.png';
import LoginHeader from './components/login-header.component';
import Navbar from 'shared/components/navbar/navbar.component';
import CardContainer from 'shared/components/card/card.component';
import Page from 'shared/components/page/page.component';

const LoginPage: FC = () => {
  return (
    <Page bgImage={SorensonLogin} title='Sign In | VRS' animate={false}>
      <Navbar showUserMenu={false} />
      <CardContainer gap={24}>
        <LoginHeader />
        <LoginForm />
      </CardContainer>
    </Page>
  );
};

export default LoginPage;

const licensedStates = [
  { value: 'US-AZ', name: 'Arizona' },
  { value: 'US-ID', name: 'Idaho' },
  { value: 'US-IL', name: 'Illinois' },
  { value: 'US-IA', name: 'Iowa' },
  { value: 'US-MI', name: 'Michigan' },
  { value: 'US-NV', name: 'Nevada' },
  { value: 'US-UT', name: 'Utah' },
  { value: 'US-WI', name: 'Wisconsin' },
  { value: 'None', name: 'None of these' },
];

export default licensedStates;

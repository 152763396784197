export default function getLinkHandler(
  event:
    | React.MouseEvent<HTMLAnchorElement, MouseEvent>
    | React.MouseEvent<HTMLSpanElement, MouseEvent>
) {
  event.preventDefault();

  if (window?.electronAPI) {
    window.electronAPI?.openLink(event.currentTarget.getAttribute('href'));
  } else {
    window.open(event.currentTarget.getAttribute('href')!, '_blank');
  }
}

import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

const InterpreterSkeleton: FC = () => {
  return (
    <>
      <Skeleton
        variant='rounded'
        animation='wave'
        height={60}
        sx={{ marginBottom: '32px' }}
      />
      <Skeleton variant='rounded' animation='wave' height={60} />
    </>
  );
};

export default InterpreterSkeleton;

import React, { FC } from 'react';
import { motion } from 'framer-motion';
import {
  Button,
  Container,
  Grid,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ThankYouBG from 'shared/assets/images/thank-you-bg.png';
import ThankYouBGMobile from 'shared/assets/images/thank-you-bg-mobile.png';

const MotionContainer = motion(Container);
const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/');
  };
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.default',
        backgroundImage: `url(${matches ? ThankYouBGMobile : ThankYouBG})`,
        backgroundSize: matches ? 'auto' : 'cover',
        backgroundPosition: matches ? 'bottom right' : 'center center',
        backgroundRepeat: 'no-repeat',
      }}
      maxWidth={false}
    >
      <Grid
        container
        item
        xs={8}
        display='flex'
        justifyContent='center'
        alignItems='center'
        rowSpacing={4}
        marginBottom={40}
        spacing={3}
      >
        <Box>
          <Typography variant='h1'>The link seems to be broken</Typography>
          <Button onClick={goBack}>Go Back</Button>
        </Box>
      </Grid>
    </MotionContainer>
  );
};

export default NotFoundPage;

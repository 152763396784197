import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';

const withRouter = (Component: React.ComponentType) => {
  const Router =
    process.env.REACT_APP_PLATFORM === 'DESKTOP' ? HashRouter : BrowserRouter;
  // eslint-disable-next-line react/display-name
  return () => (
    <Router>
      <Component />
    </Router>
  );
};

export default withRouter;

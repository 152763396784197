const RELAY_RESPONSES = {
  DUPLICATE_REQUEST: 'Your request cannot be completed at this time',
};

const LINKS = {
  SIGN_UP_SORENSON_EXPRESS: 'sign up with Sorenson Express',
};

const ERROR_STRINGS = {
  SIGN_IN_FAILURE: `We didn’t recognize the phone number or password you entered. Please try again.`,
  INVALID_LINK:
    'Sorry, it seems that this link is invalid. Double-check the link and try pasting again.',
  DUPLICATE_REQUEST:
    'An interpreter has already been requested. Please wait a moment for the interpreter to join.',
  UNABLE_TO_REQUEST:
    'We were unable to request an interpreter right now, please try again later.',
  UNABLE_GET_INTERPRETER_BY_KILLSWITCH:
    'Sorry, testing of the Sorenson service is currently unavailable while we make updates.',
  UNAUTHORIZED_PHONE:
    'Sorry; it looks like this phone number isn’t authorized to make a VRS call. Want to try again? Or ',
};

const ZOOM_INSTRUCTIONS = {
  MEETING_LINK: {
    LABEL: 'Meeting Link (Zoom)',
    INSTRUCTIONS: [
      'Locate the information you received for this Zoom meeting (i.e., email, calendar invite, forwarded link).',
      'Copy and paste the Zoom link into the ‘Meeting Link’ field on the ‘Invite Interpreter’ form.',
    ],
  },
  MEETING_AUDIO_NUMBER: {
    LABEL: 'Meeting Audio Number',
    INSTRUCTIONS: [
      'Locate the information you received for this Zoom meeting (i.e., email, calendar invite, forwarded link).',
      'Copy one of the numbers from the ‘Dial by your location’ list on the Zoom meeting invite.',
      'Paste the ten-digit number (without the +1) into the ‘Meeting Audio Number’ field on the ‘Invite Interpreter’ form.',
    ],
  },
  MEETING_ID: {
    LABEL: 'Meeting ID',
    INSTRUCTIONS: [],
  },
  PASSCODE: {
    LABEL: 'Passcode (if applicable)',
    INSTRUCTIONS: [
      'Locate the information you received for this Zoom meeting (i.e., email, calendar invite, forwarded link).',
      'Copy and paste the passcode into the ‘Passcode’ field on the ‘Invite Interpreter’ form.',
    ],
  },
};

const TEAMS_INSTRUCTIONS = {
  MEETING_LINK: {
    LABEL: 'Meeting Link (Teams)',
    INSTRUCTIONS: [
      'Locate the information you received for this Teams meeting (i.e., email, calendar invite, forwarded link).',
      'Right-click on the ‘Join Microsoft Teams Meeting’ link.',
      'Select ‘Copy Hyperlink.’',
      'Paste the hyperlink into the ‘Meeting Link’ field on the ‘Invite Interpreter’ form.',
    ],
  },
  MEETING_AUDIO_NUMBER: {
    LABEL: 'Meeting Audio Number',
    INSTRUCTIONS: [
      'Locate the information you received for this Teams meeting (i.e., email, calendar invite, forwarded link).',
      'Right-click on the audio phone number link.',
      'Select ‘Copy Hyperlink.’',
      'Paste the hyperlink into the ‘Meeting Audio Number’ field on the ‘Invite Interpreter’ form.',
    ],
  },
  PHONE_CONFERENCE_ID: {
    LABEL: 'Phone Conference ID',
    INSTRUCTIONS: [
      'Locate the information you received for this Teams meeting (i.e., email, calendar invite, forwarded link).',
      'Copy and paste the nine-digit conference ID into the ‘Meeting/Conference ID’ field on the ‘Invite Interpreter’ form.',
    ],
  },
  PASSCODE: {
    LABEL: 'Passcode (if applicable)',
    INSTRUCTIONS: [
      'Locate the information you received for this Teams meeting (i.e., email, calendar invite, forwarded link).',
      'Copy and paste the passcode into the ‘Passcode’ field on the ‘Invite Interpreter’ form.',
    ],
  },
};

export {
  LINKS,
  RELAY_RESPONSES,
  ERROR_STRINGS,
  ZOOM_INSTRUCTIONS,
  TEAMS_INSTRUCTIONS,
};

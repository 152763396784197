import { Platforms, PASSCODE_IDENTIFIER } from 'shared/constants';
import getRegexByPlatform from './getRegexByPlatform';

export default async function getPhoneAndPasscode(platform: Platforms) {
  const output = {
    phone: '',
    conferenceId: '',
  };

  const data = await navigator.clipboard.readText();

  if (PASSCODE_IDENTIFIER.test(data)) {
    const phoneAndPasscode = data.match(getRegexByPlatform(platform));

    if (!phoneAndPasscode) {
      return output;
    }
    const phone = phoneAndPasscode[1]
      ? phoneAndPasscode[1]
      : phoneAndPasscode[3];

    output.phone = phone.replace(/[-\s]/g, '');

    output.conferenceId = phoneAndPasscode[2] || phoneAndPasscode[4];
  } else {
    output.phone = data.split(/\D/g).join('').slice(-10);
  }
  return output;
}

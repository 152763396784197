import React from 'react';
import { Box, Typography } from '@mui/material';

const LoginHeader = () => {
  return (
    <Box>
      <Typography variant='h2'>Sign In</Typography>
      <Typography
        variant='subtitle1'
        sx={{
          marginTop: '8px',
        }}
      >
        Welcome back! Please enter your details
      </Typography>
    </Box>
  );
};

export default LoginHeader;

import React, { useEffect } from 'react';
import useIsEnable from '../hooks/is-enabled';
import { useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import { Box, FormControl, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InputField from 'shared/components/input/input.component';
import {
  INVITE_URL_TEAMS,
  PHONE_NUMBER_REGEX,
  PASSCODE_REGEX,
  ZOOM_INPUT_PATTERN,
  TEAMS_INPUT_PATTERN,
  Platforms,
  STRINGS,
  INVITE_URL_ZOOM,
} from 'shared/constants';
import LoadingButton from 'shared/components/loading-button/loading-button.component';
import useRequestInterpreter, { IFormInput } from '../hooks/request.service';
import { useAuth } from 'shared/contexts/auth-context';
import { UserType } from 'shared/constants';
import MaskedNumber from 'shared/components/masked-number/masked-number';
import {
  FormControlLabelCustom,
  FormLabelCustom,
  RadioCustom,
} from 'shared/components/radio-button/radio-button';
import RadioGroup from '@mui/material/RadioGroup';
import { getPasscodeFromURL, getPlatform } from '../helpers';
import FlexBox from 'shared/components/flex-box';
import TogglePlatformBtn from './toggle-platform-btn';
import {
  ZOOM_INSTRUCTIONS,
  TEAMS_INSTRUCTIONS,
} from 'shared/constants/strings';
import InputLabel from './form-input-label';
import getPhoneAndPasscode from '../helpers/getPhoneAndPasscode';

const InterpreterRequestForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    watch,
    reset,
  } = useForm<IFormInput>();

  const { sorensonUser } = useAuth();
  const { userType } = sorensonUser;
  const { languageSelector, passCode, teams, engageTeamsPasscode } =
    useIsEnable(userType);
  const { VRI } = UserType;

  const { submit, callId, loading } = useRequestInterpreter();

  const [platform, setPlatform] = React.useState<Platforms>(Platforms.Zoom);

  const [phoneAndPasscode, setPhoneAndPasscode] = React.useState({
    phone: '',
    conferenceId: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (callId) {
      navigate(`thank-you`);
    }
    setValue('meetingPlatform', platform);
  }, [callId, navigate]);

  const handleAdornment = (error?: FieldError): React.ReactNode => {
    const adornment = error ? (
      <CloseIcon color='error' />
    ) : loading ? (
      <CircularProgress style={{ color: '#d6732e' }} size={16} />
    ) : isSubmitSuccessful ? (
      <CheckIcon color='success' />
    ) : null;

    return (
      <div
        style={{
          margin: '0 12px',
          display: 'flex',
        }}
      >
        {adornment}
      </div>
    );
  };

  const handleOnPaste = async () => {
    try {
      const { conferenceId, phone } = await getPhoneAndPasscode(platform);
      setValue('phone', phone);
      if (phoneAndPasscode.conferenceId) {
        setPhoneAndPasscode({
          ...phoneAndPasscode,
          phone,
        });
        return;
      }
      setValue('conferenceId', conferenceId);
      setPhoneAndPasscode({
        phone,
        conferenceId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnURLPaste = async () => {
    if (phoneAndPasscode.conferenceId) {
      return;
    }
    try {
      const data = await navigator.clipboard.readText();
      const platform = getPlatform(data);
      const meetingId = getPasscodeFromURL(data);

      if (platform === Platforms.Zoom && meetingId) {
        setValue('conferenceId', meetingId);
        setPhoneAndPasscode({
          ...phoneAndPasscode,
          conferenceId: meetingId,
        });
      } else {
        setValue('conferenceId', '');
        setPhoneAndPasscode({
          ...phoneAndPasscode,
          conferenceId: '',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlatform = (_: React.MouseEvent, platform: Platforms | null) => {
    if (!platform) {
      return;
    }
    setPlatform(platform);
    setValue('meetingPlatform', platform);
    reset();
    setPhoneAndPasscode({
      phone: '',
      conferenceId: '',
    });
  };

  const isZoomSelected = () => {
    return platform === Platforms.Zoom;
  };

  const isTeamsPasscode = (): boolean => {
    return isZoomSelected() || engageTeamsPasscode;
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mb='24px'>
        <Typography variant='subtitle1'>
          Enter the necessary meeting information for your{' '}
          {platform.charAt(0).toUpperCase() + platform.slice(1)} meeting
        </Typography>
      </Box>
      <FlexBox>
        {teams ? (
          <TogglePlatformBtn
            platform={platform}
            handlePlatform={handlePlatform}
          />
        ) : null}
        <FlexBox>
          <InputField
            id='meeting-link'
            InputProps={{
              endAdornment: handleAdornment(errors.link),
            }}
            onPaste={handleOnURLPaste}
            label={
              <InputLabel
                {...(isZoomSelected()
                  ? ZOOM_INSTRUCTIONS.MEETING_LINK
                  : TEAMS_INSTRUCTIONS.MEETING_LINK)}
              />
            }
            error={!!errors.link}
            helperText={errors.link?.message}
            InputLabelProps={{
              shrink: true,
              variant: 'standard',
            }}
            multiline
            placeholder={`${isZoomSelected() ? 'Zoom' : 'Teams'} Meeting Link`}
            {...register('link', {
              required: 'Link is required',
              pattern: {
                value: isZoomSelected() ? INVITE_URL_ZOOM : INVITE_URL_TEAMS,
                message: STRINGS.ERROR_STRINGS.INVALID_LINK,
              },
            })}
            sx={{
              '.MuiInputBase-multiline': {
                height: watch('link') ? 'auto' : '48px',
              },
            }}
          />
          {userType !== VRI && (
            <InputField
              id='meeting-hearing-phone'
              label={
                <InputLabel
                  {...(isZoomSelected()
                    ? ZOOM_INSTRUCTIONS.MEETING_AUDIO_NUMBER
                    : TEAMS_INSTRUCTIONS.MEETING_AUDIO_NUMBER)}
                />
              }
              onPaste={handleOnPaste}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              InputProps={{
                inputComponent: MaskedNumber as any,
                endAdornment: handleAdornment(errors.phone),
                value: phoneAndPasscode.phone,
              }}
              InputLabelProps={{ shrink: true, variant: 'standard' }}
              placeholder='Meeting Audio Number'
              {...register('phone', {
                required: 'Meeting Audio Number is required',
                pattern: {
                  value: PHONE_NUMBER_REGEX,
                  message: 'Invalid Meeting Audio Number',
                },
                onChange: (e) => {
                  setPhoneAndPasscode({
                    ...phoneAndPasscode,
                    phone: e.target.value,
                  });
                },
              })}
            />
          )}
          {userType !== VRI && (
            <InputField
              id='conference-id'
              label={
                <InputLabel
                  {...(isZoomSelected()
                    ? ZOOM_INSTRUCTIONS.MEETING_ID
                    : TEAMS_INSTRUCTIONS.PHONE_CONFERENCE_ID)}
                />
              }
              error={!!errors.conferenceId}
              helperText={errors.conferenceId?.message}
              InputProps={{
                inputComponent: MaskedNumber as any,
                endAdornment: handleAdornment(errors.conferenceId),
                value: phoneAndPasscode.conferenceId,
                inputProps: {
                  format: isZoomSelected()
                    ? ZOOM_INPUT_PATTERN
                    : TEAMS_INPUT_PATTERN,
                },
              }}
              InputLabelProps={{ shrink: true, variant: 'standard' }}
              placeholder={`${
                isZoomSelected() ? `Meeting ID` : 'Phone Conference ID'
              }`}
              {...register(
                `${isZoomSelected() ? 'conferenceId' : 'passcode'}`,
                {
                  required: 'Meeting/Conference Id is required',
                  pattern: {
                    value: PASSCODE_REGEX,
                    message: 'Invalid Meeting/Conference Id',
                  },
                  onChange: (e) => {
                    setPhoneAndPasscode({
                      ...phoneAndPasscode,
                      conferenceId: e.target.value,
                    });
                  },
                }
              )}
            />
          )}
          {passCode && isTeamsPasscode() ? (
            <InputField
              id='passcode'
              label={
                <InputLabel
                  {...(isZoomSelected()
                    ? ZOOM_INSTRUCTIONS.PASSCODE
                    : TEAMS_INSTRUCTIONS.PASSCODE)}
                />
              }
              error={!!errors.passcode}
              InputLabelProps={{ shrink: true, variant: 'standard' }}
              placeholder='Passcode'
              {...register('passcode')}
            />
          ) : null}
          {languageSelector && (
            <FormControl
              fullWidth
              sx={{
                marginTop: '24px',
              }}
            >
              <FormLabelCustom id='language-radio-buttons-group-label'>
                Please select your preferred interpreter language
              </FormLabelCustom>
              <RadioGroup
                aria-labelledby='language-radio-buttons-group-label'
                defaultValue='English'
                sx={{ gap: '8px' }}
              >
                <FormControlLabelCustom
                  value='English'
                  label='English'
                  control={<RadioCustom />}
                  {...register('language')}
                />
                <FormControlLabelCustom
                  value='Spanish'
                  label='Spanish'
                  control={<RadioCustom />}
                  {...register('language')}
                />
              </RadioGroup>
            </FormControl>
          )}
        </FlexBox>
        <LoadingButton
          type='submit'
          loading={loading}
          sx={{ marginTop: '24px' }}
        >
          Send meeting invite
        </LoadingButton>

        <Box
          sx={{
            // position: 'relative',
            margin: '15px auto 0px ',
            // display: 'inline',
            // bottom: '40px',
            // left: '10px',
          }}
        >
          <Typography
            variant='caption'
            sx={{
              a: {
                color: 'rgba(0, 0, 0, 0.84)',
                fontSize: '12px',
              },
            }}
          >
            <a
              rel='noreferrer'
              target='_blank'
              href={
                'https://sorenson.com/wp-content/uploads/2023/08/SorensonCommunications-VRI-EULA-FINAL2022-11.pdf'
              }
            >
              Terms and conditions
            </a>
          </Typography>
        </Box>
      </FlexBox>
    </form>
  );
};

export default InterpreterRequestForm;

import { useAuth0 } from '@auth0/auth0-react';
import InterpreterSkeleton from 'modules/interpreter/request/components/skeleton.component';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'shared/contexts/auth-context';

interface IProtectedRoute {
  children: JSX.Element;
}

export default function ProtectedRoute({ children }: IProtectedRoute) {
  const { isLoggedIn, loading } = useAuth();
  const { isLoading } = useAuth0();
  const location = useLocation();

  if (loading || isLoading) {
    return <InterpreterSkeleton />;
  }
  if (isLoggedIn) {
    return children;
  }
  return <Navigate to='/sign-in' state={{ from: location }} replace />;
}

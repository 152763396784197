import React from 'react';
import { FormLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';

export const FormLabelCustom = styled(FormLabel)(({ theme }) => ({
  '&': {
    color: `${theme.palette.text.primary} !important`,
    marginBottom: '6px',
    fontSize: '14px',
  },
}));

export const RadioCustom = styled(Radio)(({ theme }) => ({
  '&': {
    color: `${theme.palette.primary.contrastText}`,
  },
}));

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

export const StyledFormControlLabel = styled(
  FormControlLabel
)<StyledFormControlLabelProps>(({ checked, theme }) => {
  return {
    border: `solid 1px ${checked ? theme.palette.primary.main : '#aeb0b0'}`,
    borderRadius: '10px',
    color: `${theme.palette.primary.contrastText} !important`,
    height: '48px',
    boxSizing: 'border-box',
    margin: '0',
    backgroundColor: `${checked ? 'rgba(61, 205, 194, 0.15)' : ''}`,
  };
});

export const FormControlLabelCustom = React.forwardRef<
  unknown,
  FormControlLabelProps
>(function FormControlLabelCustom(props, ref) {
  const radioGroup = useRadioGroup();
  return (
    <StyledFormControlLabel
      ref={ref}
      checked={radioGroup?.value === props.value}
      {...props}
    />
  );
});

import { Platforms, UserType } from 'shared/constants';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';
import { removePhoneFormat } from 'shared/services/format-phone-number';
import getConferenceId from './getConferenceId';

const getRequestBody = (
  link: string,
  sorensonUser: ISorensonUser,
  ip: string,
  conferenceId: string,
  platform: Platforms,
  token: string,
  location?: string,
  phone?: string,
  language?: string,
  billingName?: string,
  billingId?: string,
  passcode?: string
) => {
  const commonBody = {
    [`${platform}URL`]: link,
    displayName: sorensonUser.name,
    passcode,
    endpointProductName: 'Sorenson Sidecar',
    endpointProductVersion: process.env.REACT_APP_ENGAGE_VERSION || '',
  };
  const vriBody = {
    email: sorensonUser.email,
    location: location !== 'None' ? location : '',
    billingName: billingName || '',
    billingId: billingId || '',
    token: token || '',
  };
  const vrsBody = {
    deafPhone: sorensonUser.phoneNumber?.toString(),
    hearingPhone: phone ? removePhoneFormat(phone) : '',
    publicIP: ip,
    interpreterLanguage: language,
  };
  const userTypeBody =
    sorensonUser.userType === UserType.VRI ? { ...vriBody } : { ...vrsBody };

  return { ...commonBody, ...userTypeBody };
};

export default getRequestBody;

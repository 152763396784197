import React, { FC, useState, useRef } from 'react';

import { setLocalItem } from 'shared/services/storage';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import LoadingButton from 'shared/components/loading-button/loading-button.component';
import { LOCATION } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import licensedStates from 'shared/constants/states';
import { useAuth } from 'shared/contexts/auth-context';
import { useAlert } from 'shared/contexts/alert.context';

import FlexBox from 'shared/components/flex-box';

const SelectLocation: FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<string>();
  const alertShown = useRef<string>('');
  const { setLocation } = useAuth();
  const { showAlert, closeAlert } = useAlert();

  const handleOnChange = (event: { target: { value: string } }) => {
    setState(event.target.value);
  };

  const handleSubmit = () => {
    if (state) {
      setLocation(state);
      setLocalItem<string>(LOCATION, JSON.stringify(state));
      if (alertShown.current) {
        closeAlert(alertShown.current);
      }
      navigate('/', { replace: true });
    } else {
      const alertId = showAlert(
        'error',
        'Please select a location from the dropdown'
      );
      alertShown.current = alertId;
    }
  };

  return (
    <Box>
      <FlexBox gap={24}>
        <FormControl fullWidth>
          <InputLabel id='location-select-label'>
            Select your location
          </InputLabel>
          <Select
            labelId='location-select-label'
            id='location-select'
            value={state || ''}
            label='Select your location'
            defaultValue='None'
            onChange={handleOnChange}
            MenuProps={{ sx: { '.MuiList-root': { bgcolor: 'white' } } }}
            sx={{
              '.MuiSelect-select': {
                backgroundColor: '#fff',
              },
            }}
          >
            {licensedStates.map(({ value, name }) => (
              <MenuItem value={value} key={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton onClick={handleSubmit} type='submit'>
          Continue
        </LoadingButton>
      </FlexBox>
    </Box>
  );
};

export default SelectLocation;

import React, { useEffect } from 'react';
import useIsEnabled from 'modules/interpreter/request/hooks/is-enabled';
import { Box, Typography } from '@mui/material';
import SorensonLogin from 'shared/assets/images/sorenson-login-new.png';
import CardContainer from 'shared/components/card/card.component';
import SelectLocation from './components/select-location.component';
import Page from 'shared/components/page/page.component';
import { useNavigate } from 'react-router-dom';

const RequestLocation = (): JSX.Element => {
  const { engageKillswitch } = useIsEnabled();
  const navigate = useNavigate();

  useEffect(() => {
    if (engageKillswitch) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engageKillswitch]);

  return (
    <Page bgImage={SorensonLogin} title='Location'>
      <CardContainer gap={22}>
        <Box>
          <Typography variant='h2'>Location</Typography>
          <Typography variant='subtitle1'>
            The requestor for sign language interpreting services is located in:
          </Typography>
        </Box>
        <SelectLocation />
      </CardContainer>
    </Page>
  );
};

export default RequestLocation;

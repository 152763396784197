import React, { FC, useEffect } from 'react';
import useIsEnable from './hooks/is-enabled';
import InterpreterRequestForm from './components/interpreter-request-form.component';
import InterpreterRequestHeader from './components/header.component';

import CardContainer from 'shared/components/card/card.component';
import Page from 'shared/components/page/page.component';

import backgroundImg from 'shared/assets/images/request-interpreter-new.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'shared/contexts/auth-context';
import { UserType } from 'shared/constants';
import InterpreterSkeleton from './components/skeleton.component';
import { useAlert } from 'shared/contexts/alert.context';
import { STRINGS } from 'shared/constants';
import UnavailableRequestForm from './components/unavailable-request-form';

const RequestPage: FC = () => {
  const navigate = useNavigate();
  const { location, isLoggedIn, sorensonUser, loading } = useAuth();
  const { showAlert } = useAlert();
  const { engageKillswitch, teams } = useIsEnable(sorensonUser.userType);

  useEffect(() => {
    if (
      !engageKillswitch &&
      !location &&
      isLoggedIn &&
      sorensonUser.userType === UserType.VRI
    ) {
      navigate('location');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorensonUser]);

  useEffect(() => {
    if (engageKillswitch) {
      showAlert(
        'error',
        STRINGS.ERROR_STRINGS.UNABLE_GET_INTERPRETER_BY_KILLSWITCH
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engageKillswitch]);

  const CardContent = () => {
    return engageKillswitch ? (
      <UnavailableRequestForm teamsSupport={teams} />
    ) : (
      <>
        <InterpreterRequestHeader />
        {loading ? <InterpreterSkeleton /> : <InterpreterRequestForm />}
      </>
    );
  };

  return (
    <Page bgImage={backgroundImg} title='Invite Interpreter'>
      <CardContainer>
        <CardContent />
      </CardContainer>
    </Page>
  );
};

export default RequestPage;

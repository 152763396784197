import React from 'react';
import useIsEnabled from 'modules/interpreter/request/hooks/is-enabled';
import { useAuth } from 'shared/contexts/auth-context';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery, Link as LinkMui } from '@mui/material';
import LoginModal from 'shared/components/modal/basic-modal.component';
import LoginCard from 'shared/components/card/login-card.component';
import { LoginModes } from 'shared/constants';
import getLinkHandler from 'shared/functions/getLinkHandler';

const LoginSelectorForm = (): JSX.Element => {
  const { handleAuthProvider, handleAuth0SignIn } = useAuth();
  const { engageVrsSignIn, engageVriSignUp, engageVriSignIn } = useIsEnabled();
  const navigate = useNavigate();
  const { Auth0, Sorenson } = LoginModes;
  const [isActive, setIsActive] = React.useState(false);
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const handleClick = (authProvider: LoginModes) => {
    handleAuthProvider(authProvider);
    const login = {
      [Auth0]: async () => await handleAuth0SignIn(),
      // [Auth0]: () => navigate('vri'),
      [Sorenson]: () => navigate('vrs'),
    };

    return login[authProvider]();
  };

  // React.useEffect(() => {
  //   // navigate('vri');
  //   () => handleClick(Auth0);
  // }, []);

  if (process.env.REACT_APP_VRI_ONLY === 'true') {
    handleClick(Auth0);

    return <div></div>;
  } else {
    return (
      <Box
        mb={'16px'}
        sx={{
          zIndex: 100,
        }}
      >
        <Box mb={'27px'}>
          <Typography
            variant='caption'
            fontSize={'14px'}
            sx={{
              a: {
                color: '#006A62',
              },
            }}
          >
            Let&apos;s help you get connected to an interpreter. Are you a
            Sorenson VRS user or{' '}
            <LinkMui
              href='mailto:SICustomerSupport@sorenson.com'
              onClick={getLinkHandler}
            >
              do you need to sign up for Sorenson Express?
            </LinkMui>
          </Typography>
        </Box>
        <Box mb={'9px'}>
          <iframe
            src='https://player.vimeo.com/video/818790786?h=0f16f6c05b'
            style={{
              width: '100%',
              height: '180px',
              backgroundColor: 'black',
              border: 'none',
            }}
            allowFullScreen
            title='Video Relay Service or Video Remote Interpreting'
          />
        </Box>
        <Box mb={'24px'}>
          <Typography variant='subtitle1'>
            (See explanation in ASL with captions)
          </Typography>
        </Box>
        <Box>
          <LoginModal
            btnText='Get an interpreter'
            headline="Let's help you get connected to an interpreter."
          >
            <Box mt={'16px'}>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                flexWrap={`${matches ? 'wrap' : 'nowrap'}`}
                gap={'24px'}
              >
                {engageVrsSignIn && (
                  <LoginCard
                    headlineText="I'm using Sorenson VRS"
                    bodyText='Sign in with a Sorenson VP phone number'
                    loginHandler={() => handleClick(Sorenson)}
                    eulaUrl='https://sorenson.com/wp-content/uploads/2023/08/2017-04-28-Sorenson-Individual-EULA-prevailing-language.pdf'
                  />
                )}
                {engageVriSignIn && (
                  <LoginCard
                    headlineText="I'm using Sorenson Express"
                    bodyText='Sign in with a Sorenson Express account'
                    loginHandler={() => handleClick(Auth0)}
                    eulaUrl='https://sorenson.com/wp-content/uploads/2023/08/SorensonCommunications-VRI-EULA-FINAL2022-11.pdf'
                  />
                )}
              </Box>
              <Box
                onClick={() => setIsActive(!isActive)}
                sx={{
                  cursor: 'pointer',
                  display: 'inline',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant='caption'
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  When can I use video relay services (VRS)?
                </Typography>
              </Box>
              {isActive && (
                <Box mt={'8px'}>
                  <Typography
                    fontSize={'12px'}
                    variant='caption'
                    lineHeight={'18px'}
                    sx={{}}
                  >
                    Sorenson VRS is only available if you are eligible for VRS
                    and have registered in the FCC&apos;s User Registration
                    Database. The cost of VRS is paid by a federally
                    administered fund. Accordingly, use of Sorenson Engage for
                    VRS calling is subject to compliance with FCC regulations
                    and Sorenson&apos;s VRS EULA. Under federal law, you may use
                    VRS only if you have a speech or hearing disability and need
                    VRS in order to communicate. Also, call participants must be
                    in a different location than the other individuals on the
                    call. VRS cannot be used for webinars or with a privacy
                    screen because the interpreter must be able to see the Deaf
                    participant at all times.
                  </Typography>
                </Box>
              )}
            </Box>
          </LoginModal>
        </Box>
        {engageVriSignUp && (
          <Box width={'75%'} margin={'auto'}>
            <Link to={'#'} style={{ textDecoration: 'none' }}>
              <Typography align='center' variant='subtitle1' lineHeight={'1.5'}>
                Sign up for a Sorenson Interpreting Enterprise account
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    );
  }
};

export default LoginSelectorForm;

import { Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  phone: number;
};

export default function PhoneNumbeFormat({ phone }: Props) {
  return (
    <Typography>
      <NumberFormat value={phone} format='(###) ### ####' displayType='text' />
    </Typography>
  );
}

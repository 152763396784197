import { alpha, styled } from '@mui/material/styles';

import TextField from '@mui/material/TextField';

const InputField = styled(TextField)(({ theme }) => ({
  '&': {
    position: 'relative',
    margin: '39px 0 0px 0',
  },
  '& label': {
    transform: 'translate(0px, -24px) scale(1)',
    color: `${theme.palette.text.primary} !important`,
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: 'transparent',
    fontSize: '16px',
    height: '48px',
    boxSizing: 'border-box',
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '& + .MuiFormHelperText-root': { margin: 0, fontSize: '12px' },
    '& fieldset': { borderColor: theme.palette.text.disabled },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginInline: 0,
      top: 0,
      '& legend': {
        display: 'none',
      },
    },
  },
  '& .MuiFormLabel-root': {
    width: '100%',
  },
}));
export default InputField;

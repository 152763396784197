import React from 'react';
import { Box, Typography } from '@mui/material';

const LoginHeader = () => {
  if (process.env.REACT_APP_VRI_ONLY === 'true') {
    return null;
  } else {
    return (
      <Box mb='12px'>
        <Typography variant='h2' fontSize='30px' lineHeight='38px'>
          Hi there! Hosting or attending a meeting today?
        </Typography>
      </Box>
    );
  }
};

export default LoginHeader;

import React, { ReactNode, FC } from 'react';
import { CardContent, Card, useTheme } from '@mui/material';

type ICardContainer = {
  children: ReactNode;
  gap?: number;
};

const CardContainer: FC<ICardContainer> = ({ children, gap = 0 }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        justifyContent: 'center',
        padding: '40px 40px 24px 40px',
        bgcolor: 'background.default',
        borderRadius: '15px',
        shadow: '0 3px 6px 0 0',
        maxWidth: '400px',
        width: '400px',
        boxSizing: 'border-box',
        [`${theme.breakpoints.down('sm')}`]: {
          marginTop: '88px',
          boxShadow: 'none',
          padding: '0px',
        },
        marginTop: '100px',
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: `${gap}px`,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default CardContainer;

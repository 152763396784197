import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';
import exitIcon from 'shared/assets/icons/exit.png';
import AvatarNavbar from '../components/avatar.component';

interface Props {
  sorensonUser: ISorensonUser;
  signOut: () => void;
  showMobileMenu: boolean;
  loading: boolean;
}

export default function UserMenuMobile({
  showMobileMenu,
  sorensonUser,
  signOut,
  loading,
}: Props) {
  return (
    <Box
      sx={{
        display: `${showMobileMenu ? 'flex' : 'none'}`,
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <AvatarNavbar
        sorensonUser={sorensonUser}
        isActive={showMobileMenu}
        loading={loading}
      />
      <List
        sx={{
          bgcolor: 'background.default',
          width: '100%',
          fontSize: '16px',
          padding: '0px',
        }}
      >
        <ListItem
          sx={{
            padding: '24px',
            '.MuiListItemText-root': {
              margin: '0px',
              display: 'inline',
            },
          }}
        >
          <ListItemButton
            onClick={signOut}
            sx={{
              padding: '0px',
            }}
          >
            <ListItemIcon
              sx={{
                marginRight: '6px',
                minWidth: '20px',
              }}
            >
              <img src={exitIcon} alt='Sign Out' />
            </ListItemIcon>
            <ListItemText
              secondary='Sign Out'
              secondaryTypographyProps={{
                fontSize: '16px',
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export const formatPhoneNumber = (value: string) => {
  return `${value.substring(0, 1)} (${value.slice(1, 4)}) ${value.slice(
    4,
    7
  )}-${value.slice(7, 11)}`;
};

export const removePhoneFormat = (value: string) => {
  return `${value.replace(/\D/g, '')}`;
};

export const removeCountryCode = (value: string) => {
  return +value.substring(1);
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import TooltipInstructions from './tooltip-instructions';

interface Props {
  LABEL: string;
  INSTRUCTIONS: Array<string>;
}

function InputLabel({ LABEL, INSTRUCTIONS }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography fontSize={14}>{LABEL}</Typography>
      {INSTRUCTIONS.length ? (
        <TooltipInstructions instructions={INSTRUCTIONS} />
      ) : null}
    </Box>
  );
}

export default InputLabel;

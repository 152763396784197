import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import ProtectedRoute from 'shared/routes/ProtectedRoute';
import Navbar from '../navbar/navbar.component';

const Layout = (): JSX.Element => {
  return (
    <ProtectedRoute>
      <Box
        sx={{
          bgcolor: 'background.default',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Navbar />
        <Outlet />
      </Box>
    </ProtectedRoute>
  );
};

export default Layout;

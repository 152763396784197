import { useState } from 'react';
import { useLocation, useNavigate, Path } from 'react-router-dom';

import { useAuth } from 'shared/contexts/auth-context';
import { LogInCredentials } from '@sorenson-eng/videophone-core-services';

interface IUseLogin {
  login: (data: LogInCredentials) => void;
  from: string;
  loginError: boolean;
  loading: boolean;
  userInterfaceModeError: string;
}

interface IState {
  from?: Path;
}

const useLogin = (): IUseLogin => {
  const { handleSorensonSignIn, loading, userInterfaceModeError } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loginError, setLoginError] = useState<boolean>(false);

  const from: string = (state as IState)?.from?.pathname || '/';

  const login = async (data: LogInCredentials) => {
    try {
      await handleSorensonSignIn(data);
      navigate(from, { replace: true });
      setLoginError(false);
    } catch (e) {
      setLoginError(true);
    }
  };
  return { login, from, loginError, loading, userInterfaceModeError };
};

export default useLogin;

import * as React from 'react';
import { Platforms } from 'shared/constants';
import ToggleButtons from 'shared/components/modal/modal.component';
import { Box } from '@mui/material';

interface Props {
  platform: Platforms;
  handlePlatform: (_: React.MouseEvent, platform: Platforms) => void;
}

function TogglePlatformBtn({ platform, handlePlatform }: Props) {
  const values = Object.entries(Platforms).map((p) => ({
    name: p[0],
    value: p[1],
  }));

  return (
    <Box
      sx={{
        marginBottom: '8px',
      }}
    >
      <ToggleButtons
        currentValue={platform}
        handleOnChange={handlePlatform}
        values={values}
      />
    </Box>
  );
}

export default TogglePlatformBtn;

import React from 'react';

import {
  Box,
  Snackbar,
  Icon,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useAlert } from 'shared/contexts/alert.context';

import {
  ERROR,
  ERROR_ACCENT,
  SUCCESS,
  SUCCESS_ACCENT,
  WARNING,
  WARNING_ACCENT,
  INFO,
  INFO_ACCENT,
  BODY_COLOR,
  SUB_TITLE_COLOR,
} from '../../styles/colors';

const AUTO_CLOSE_TIME = 5000;

const SnackbarAlert = () => {
  const { alerts, closeAlert } = useAlert();
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const alertType = {
    error: {
      icon: 'close',
      color: ERROR,
      backgroundColor: ERROR_ACCENT,
    },
    success: {
      icon: 'check',
      color: SUCCESS,
      backgroundColor: SUCCESS_ACCENT,
      autoClose: AUTO_CLOSE_TIME,
    },
    info: {
      icon: 'priority_high',
      color: INFO,
      backgroundColor: INFO_ACCENT,
    },
    warning: {
      icon: 'priority_high',
      color: WARNING,
      backgroundColor: WARNING_ACCENT,
    },
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        width: matches ? '320px' : '469px',
        margin: '85px auto 0',
      }}
      open
    >
      <Box>
        {alerts.map(({ type, message, id }) => {
          return (
            <Box sx={{ marginBottom: '10px' }} key={id}>
              <Box
                sx={{
                  display: 'grid',
                  alignContent: 'center',
                  alignItems: 'center',
                  gridTemplateColumns: '1fr 5fr 1fr',
                  minHeight: '52px',
                  borderRadius: '3px',
                  bgcolor: alertType[type].backgroundColor,
                  borderLeft: `solid 8px ${alertType[type].color}`,
                  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16);',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {alertType[type].icon === 'info' ? (
                    <Icon
                      sx={{
                        color: alertType[type].color,
                        fontSize: '30px',
                      }}
                    >
                      {alertType[type].icon}
                    </Icon>
                  ) : (
                    <Box
                      sx={{
                        bgcolor: alertType[type].color,
                        width: '24px',
                        height: '24px',
                        borderRadius: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon
                        sx={{
                          color: 'white',
                          fontSize: '18px',
                          alignSelf: 'center',
                        }}
                      >
                        {alertType[type].icon}
                      </Icon>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: BODY_COLOR,
                      alignSelf: 'center',
                    }}
                  >
                    {message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    sx={{
                      height: '18px',
                      width: '12px',
                      color: 'secondary.main',
                      alignSelf: 'center',
                    }}
                    onClick={() => closeAlert(id)}
                  >
                    <Icon
                      sx={{
                        color: SUB_TITLE_COLOR,
                      }}
                    >
                      close
                    </Icon>
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Snackbar>
  );
};

export default SnackbarAlert;

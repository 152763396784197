import React from 'react';
import { Box, Typography } from '@mui/material';

const InterpreterRequestHeader = () => {
  return (
    <Box>
      <Typography variant='h2' sx={{ marginBottom: '8px' }}>
        Let&apos;s get you into your meeting.
      </Typography>
    </Box>
  );
};

export default InterpreterRequestHeader;

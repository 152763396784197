import React, {
  FC,
  createContext,
  useState,
  ReactElement,
  useContext,
} from 'react';

import { lightTheme } from 'shared/styles/theme';

export const ThemeMode = createContext({
  toggleThemeMode: () => {},
  darkMode: false,
  theme: lightTheme,
});

interface IThemeMode {
  children: ReactElement;
}

export const ThemeModeProvider: FC<IThemeMode> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [theme, setTheme] = useState(lightTheme);

  const toggleThemeMode = () => {
    setDarkMode(!darkMode);
    setTheme(lightTheme);
  };

  return (
    <ThemeMode.Provider value={{ toggleThemeMode, darkMode, theme }}>
      {children}
    </ThemeMode.Provider>
  );
};

const useTheme = () => useContext(ThemeMode);

export default useTheme;

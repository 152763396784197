import { Platforms, UserType, PASSCODE_FORMAT } from 'shared/constants';
import getRequestBody from './getRequestBody';

const getPasscodeFromURL = (url: string) => {
  const passcode = decodeURI(url).match(PASSCODE_FORMAT);
  return (passcode && passcode[0]) || undefined;
};

const getPlatform = (link: string): Platforms => {
  return link.toLowerCase().includes(Platforms.Zoom)
    ? Platforms.Zoom
    : Platforms.Teams;
};

const getSorensonEndpoint = (platform: Platforms, userType: UserType) => {
  const userTypeEndpoint =
    userType === UserType.VRI
      ? process.env.REACT_APP_SORENSON_URL_VRI
      : process.env.REACT_APP_SORENSON_URL_VRS;

  return `${userTypeEndpoint}${platform}`;
};

export { getPasscodeFromURL, getPlatform, getSorensonEndpoint, getRequestBody };

import React, { FC } from 'react';

import Box from '@mui/material/Box';

type IFlexBox = {
  children: React.ReactNode;
  gap?: number;
};

const FlexBox: FC<IFlexBox> = ({ children, gap = 0 }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: `${gap}px` }}>
    {children}
  </Box>
);
export default FlexBox;

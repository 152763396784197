import {
  Platforms,
  ZOOM_PHONE_AND_PASSCODE,
  TEAMS_PHONE_AND_PASSCODE,
} from 'shared/constants';

export default function getRegexByPlatform(platform: Platforms): RegExp {
  const { Teams, Zoom } = Platforms;

  const regexByPlatform = {
    [Teams]: TEAMS_PHONE_AND_PASSCODE,
    [Zoom]: ZOOM_PHONE_AND_PASSCODE,
  };

  return regexByPlatform[platform];
}

import * as React from 'react';
import DesktopNavBar from './components/desktop.component';
import MobileNavBar from './components/mobile.component';
import { useMediaQuery } from '@mui/material';
import { useAuth } from 'shared/contexts/auth-context';

interface INavbar {
  showUserMenu?: boolean;
}

const Navbar: React.FC<INavbar> = ({ showUserMenu = true }) => {
  const { signOut, sorensonUser, loading } = useAuth();

  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [showMobileMenu, setMobileMenu] = React.useState(false);

  const handleMobileMenu = () => setMobileMenu(!showMobileMenu);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const commonProps = { sorensonUser, signOut };

  return matches ? (
    <DesktopNavBar
      handleOpenUserMenu={handleOpenUserMenu}
      anchorElUser={anchorElUser}
      handleCloseUserMenu={handleCloseUserMenu}
      showUserMenu={showUserMenu}
      loading={loading}
      {...commonProps}
    />
  ) : (
    <MobileNavBar
      handleMobileMenu={handleMobileMenu}
      showMobileMenu={showMobileMenu}
      showUserMenu={showUserMenu}
      loading={loading}
      {...commonProps}
    />
  );
};

export default Navbar;

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import exitIcon from 'shared/assets/icons/exit.png';

interface Props {
  anchorElUser: HTMLElement | null;
  handleCloseUserMenu: () => void;
  signOut: () => void;
}

export default function UserMenu({
  anchorElUser,
  handleCloseUserMenu,
  signOut,
}: Props) {
  return (
    <Menu
      sx={{
        mt: '85px',
        '.MuiMenu-paper': {
          bgcolor: 'background.default',
          borderRadius: '0 0 5px 5px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        },
        '.MuiMenu-list': {
          padding: '0rem',
        },
      }}
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem
        onClick={signOut}
        sx={{
          width: '160px',
          height: '52px',
        }}
      >
        <ListItemIcon>
          <img src={exitIcon} alt='log out' />
        </ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </Menu>
  );
}

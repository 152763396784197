import { FC, createContext, useState, useContext, useEffect } from 'react';
import {
  SorensonAccountProvider,
  LogInCredentials,
  Core,
  UserLoginStatus,
  InterfaceMode,
} from '@sorenson-eng/videophone-core-services';
import { useAuth0 } from '@auth0/auth0-react';
import {
  setLocalItem,
  getLocalValue,
  removeLocalItem,
} from 'shared/services/storage';
import { publicIpv4 } from 'public-ip';
import {
  ENGAGE_USER,
  LOCAL_KEYS,
  LoginModes,
  AUTH_PROVIDER,
  UserType,
} from 'shared/constants';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';
import IAuthContext from 'shared/interfaces/auth-context.interface';
import { ERROR_STRINGS } from 'shared/constants/strings';

const sorensonUserInitial: ISorensonUser = {
  name: '',
  phoneNumber: 0,
  email: '',
  userType: UserType.BLANK,
};

interface IAuthContextProvider {
  children: React.ReactNode;
}

declare let pendo: any;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<IAuthContext>(null!);

export const AuthContextProvider: FC<IAuthContextProvider> = ({ children }) => {
  const {
    isAuthenticated,
    error,
    isLoading,
    user: auth0User,
    logout,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const { Auth0 } = LoginModes;

  const authProviderInitialState = (): LoginModes | null => {
    const load = localStorage.getItem(AUTH_PROVIDER);
    if (!load) {
      return null;
    }
    return load as LoginModes;
  };

  const [authProvider, setAuthProvider] = useState<LoginModes | null>(
    authProviderInitialState()
  );

  const handleAuthProvider = (authProvider: LoginModes) => {
    localStorage.setItem(AUTH_PROVIDER, authProvider);
    setAuthProvider(authProvider);
  };

  const [loading, setLoading] = useState<boolean>(false);

  if (process.env.REACT_APP_CORE_REQUEST_ENDPOINT)
    Core.updateConfig({
      coreRequestUrl: process.env.REACT_APP_CORE_REQUEST_ENDPOINT,
    });

  const [sorensonUser, setSorensonUser] = useState<ISorensonUser>(
    getLocalValue<ISorensonUser>(LOCAL_KEYS.SORENSONUSER) || sorensonUserInitial
  );

  const [accessToken, setAccessToken] = useState<string>('');
  const [userInterfaceModeError, setUserInterfaceModeError] =
    useState<string>('');

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    (getLocalValue<boolean>(LOCAL_KEYS.SESSION_KEY) as boolean) || false
  );
  const [ip, setIp] = useState<string>('');

  const [location, setLocation] = useState<string>();

  const getTokenSilently = async () => {
    return authProvider === Auth0 ? await getAccessTokenSilently() : '';
  };

  const setUserData = (
    name: string,
    phoneNumber: number,
    userType: UserType,
    email: string
  ) => {
    setSorensonUser({ name, phoneNumber, userType, email });
    setLocalItem(
      LOCAL_KEYS.SORENSONUSER,
      JSON.stringify({ name, phoneNumber, userType })
    );
  };

  const clearUserData = () => {
    setSorensonUser(sorensonUserInitial);
    setLocation(undefined);
    setIsLoggedIn(false);
    removeLocalItem(LOCAL_KEYS.SORENSONUSER);
    removeLocalItem(LOCAL_KEYS.SESSION_KEY);
    removeLocalItem(LOCAL_KEYS.AUTH_TOKEN);
    removeLocalItem(LOCAL_KEYS.MAC_KEY);
    removeLocalItem(LOCAL_KEYS.LOCATION);
    removeLocalItem(AUTH_PROVIDER);
  };

  const getIp = async () => {
    try {
      const userPublicIp = await publicIpv4();
      setIp(userPublicIp);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePendo = (id: string) => {
    pendo.initialize({
      visitor: {
        id,
      },
    });
  };

  const handleAuth0SignIn = async () => {
    try {
      await loginWithRedirect({
        redirectUri: `${window.location.origin}/location`,
      });
    } catch (e) {
      console.log('Error while signing out', e, error);
    }
  };

  const handleSorensonSignIn = async ({
    username,
    password,
  }: LogInCredentials) => {
    const provider = new SorensonAccountProvider();
    try {
      setLoading(true);
      const { loginStatus, statusMessage, interfaceMode } =
        await provider.userLoginCheck({
          username,
          password,
        });

      if (
        [
          InterfaceMode.PortedMode,
          InterfaceMode.HearingMode,
          InterfaceMode.EntityHearingAssigned,
        ].includes(interfaceMode)
      ) {
        setUserInterfaceModeError(ERROR_STRINGS.UNAUTHORIZED_PHONE);
        throw new Error(ERROR_STRINGS.UNAUTHORIZED_PHONE);
      }

      if (loginStatus !== UserLoginStatus.notValid) {
        setUserData(ENGAGE_USER || username, +username, UserType.VRS, '');
        setLocalItem<boolean>(LOCAL_KEYS.SESSION_KEY, true);
        setIsLoggedIn(true);
        handlePendo(username);
      } else {
        setUserInterfaceModeError(`${ERROR_STRINGS.SIGN_IN_FAILURE} Or `);
        throw new Error(statusMessage);
      }
    } catch (e) {
      console.log('There was an error while signing in', e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      if (authProvider === Auth0) {
        logout({ returnTo: `${window.location.origin}/sign-in` });
      }
      clearUserData();
    } catch (e) {
      console.log('Error while signing out', e, error);
    }
  };

  useEffect(() => {
    const locationValue = getLocalValue<string>(LOCAL_KEYS.LOCATION);
    if (locationValue) setLocation(locationValue);
    if (!ip) getIp();
  }, [ip, location]);

  useEffect(() => {
    if (authProvider === Auth0) {
      setIsLoggedIn(isAuthenticated);
      setLoading(isLoading);
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (auth0User && isAuthenticated) {
      setSorensonUser({
        name: auth0User.name || '',
        phoneNumber: undefined,
        email: auth0User.email || '',
        userType: UserType.VRI,
      });
      handlePendo(auth0User.email!);
    }
  }, [auth0User?.name, auth0User?.email]);

  return (
    <AuthContext.Provider
      value={{
        userInterfaceModeError,
        sorensonUser,
        ip,
        location,
        accessToken,
        isLoggedIn,
        loading,
        setAccessToken,
        setUserData,
        signOut,
        setLocation,
        getTokenSilently,
        handleAuthProvider,
        handleAuth0SignIn,
        handleSorensonSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  isOpen: boolean;
};

const hamburgerIcon = {
  'span:nth-of-type(even)': {
    left: '50%',
  },
  'span:nth-of-type(odd)': {
    left: '2.5px',
  },
  'span:nth-of-type(1),span:nth-of-type(2)': {
    top: '6px',
  },
  'span:nth-of-type(3), span:nth-of-type(4)': {
    top: '11px',
  },
  'span:nth-of-type(5), span:nth-of-type(6)': {
    top: '16px',
  },
} as React.CSSProperties;

const crossIcon = {
  'span:nth-of-type(1), span:nth-of-type(6)': {
    transform: 'rotate(45deg)',
  },
  'span:nth-of-type(2), span:nth-of-type(5)': {
    transform: 'rotate(-45deg)',
  },
  'span:nth-of-type(1)': {
    left: '6px',
    top: '8px',
  },
  'span:nth-of-type(2)': {
    right: '6px',
    top: '8px',
  },
  'span:nth-of-type(3)': {
    left: '-50%',
    opacity: '0',
  },
  'span:nth-of-type(4)': {
    left: '100%',
    opacity: '0',
  },
  'span:nth-of-type(5)': {
    left: '6px',
    top: '14px',
  },
  'span:nth-of-type(6)': {
    left: '12px',
    top: '14px',
  },
} as React.CSSProperties;

const MobileMenuIcon2 = styled(Box)(({ theme }) => ({
  '&': {
    width: '24px',
    height: '24px',
    position: 'relative',
    transform: 'rotate(0deg)',
    transition: '0.5s ease-in-out',
  },
  span: {
    backgroundColor: theme.palette.background.default,
    display: 'block',
    position: 'absolute',
    height: '2px',
    width: '10px',
    opacity: '1',
    transform: 'rotate(0deg)',
    transition: '0.25s ease-in-out',
  },
}));

export default function MobileMenuIcon({ isOpen }: Props) {
  const handleStyles = (): React.CSSProperties =>
    !isOpen ? hamburgerIcon : { ...hamburgerIcon, ...crossIcon };

  return (
    <MobileMenuIcon2 sx={handleStyles}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </MobileMenuIcon2>
  );
}

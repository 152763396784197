import { UserType } from 'shared/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

type CommonFlags = {
  engageKillswitch: boolean;
  engageVriSignIn: boolean;
  engageVrsSignIn: boolean;
  engageVriSignUp: boolean;
  engageTeamsPasscode: boolean;
};

type CrossFlags = {
  languageSelector: boolean;
  passCode: boolean;
  teams: boolean;
};

type UseIsEnabled = CommonFlags & CrossFlags;

function useIsEnabled(userType?: UserType): UseIsEnabled {
  const {
    engageKillswitch,
    engageVriSignIn,
    engageVrsSignIn,
    engageVriSignUp,
    vriLanguageSelector,
    vriTeamsSupport,
    vriPasscode,
    vrsTeamsSupport,
    languageSelector,
    vrsPasscode,
    engageTeamsPasscode,
  } = useFlags();

  const commonFlags: CommonFlags = {
    engageKillswitch,
    engageVriSignIn,
    engageVrsSignIn,
    engageVriSignUp,
    engageTeamsPasscode,
  };

  const crossFlags: { [key: string]: boolean } = {
    teams: false,
    languageSelector: false,
    passCode: false,
  };

  if (userType) {
    const { VRI, VRS } = UserType;

    const userTypeflags: { [key: string]: boolean[] } = {
      [VRI]: [vriTeamsSupport, vriLanguageSelector, vriPasscode],
      [VRS]: [vrsTeamsSupport, languageSelector, vrsPasscode],
    };

    Object.keys(crossFlags).forEach(
      (flag, idx) => (crossFlags[flag] = userTypeflags[userType][idx])
    );
  }

  return {
    ...commonFlags,
    ...crossFlags,
  } as UseIsEnabled;
}

export default useIsEnabled;

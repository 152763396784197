export * as STRINGS from './strings';

export const INVITE_URL_ZOOM =
  /(https:\/\/)(((\S*\.)*(zoom\.us|zoomgov\.com)\/(j|my)\/\S+))/;

export const INVITE_URL_TEAMS =
  /(teams\.(microsoft|live)\.com)(\/(l|meet)\/((meetup-join\/\S*)|(\S*)))/;

export const PHONE_NUMBER_REGEX =
  /((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})/;

export const PASSCODE_REGEX = /\d{9}|[\S\s]{11}/;

export const PASSCODE_FORMAT = /\d{9,12}/;

export const ZOOM_MEETING_ID = /\d{11}/;

export const ZOOM_INPUT_PATTERN = '### ### ### ##';

export const TEAMS_INPUT_PATTERN = '### ### ###';

export const TEAMS_CONFERENCE_ID = /\d{9}/;

export const ZOOM_PHONE_AND_PASSCODE = /(?:\+)?1(\d+),,(\d+)#?/;

export const TEAMS_PHONE_AND_PASSCODE =
  /(?:(?:tel:\+)?1(\d+),,(\d+)#?)|(?:(?:\+1)?\s(\d{3}-\d{3}-\d{4}),,(\d+)#?)/;

export const PASSCODE_IDENTIFIER = /,,\d{7,12}#?/;

export enum Platforms {
  Zoom = 'zoom',
  Teams = 'teams',
}

export enum UserType {
  VRS = 'VRS',
  VRI = 'VRI',
  BLANK = '',
}

export const LOCATION = 'location';

export const AUTH_PROVIDER = 'authProvider';

export enum LoginModes {
  Auth0 = 'Auth0',
  Sorenson = 'Sorenson',
}

export enum LOCAL_KEYS {
  'SESSION_KEY' = 'is_logged_in',
  'AUTH_TOKEN' = 'auth_token',
  'SORENSONUSER' = 'sorenson_user',
  'MAC_KEY' = 'mac_store_key',
  'LOCATION' = 'location',
}

export const ENGAGE_USER = 'Unknown caller';

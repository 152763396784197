import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';
import SorensonLogoNavbar from '../components/logo.component';
import UserMenuMobile from './user-menu-mobile.component';
import ModalOverlayBg from './modal-overlay-background';
import MobileMenuIcon from './mobile-menu-icon.component';

interface Props {
  sorensonUser: ISorensonUser;
  signOut: () => void;
  handleMobileMenu: () => void;
  showMobileMenu: boolean;
  showUserMenu?: boolean;
  loading: boolean;
}

export default function MobileNavBar({
  handleMobileMenu,
  showMobileMenu,
  sorensonUser,
  signOut,
  showUserMenu = true,
  loading,
}: Props) {
  const commonProps = { showMobileMenu, sorensonUser, signOut };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
      }}
    >
      <AppBar color='secondary'>
        <Container
          disableGutters
          sx={{
            position: 'relative',
            height: '100%',
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 24px',
            }}
          >
            <SorensonLogoNavbar />
            {showUserMenu && (
              <IconButton
                onClick={handleMobileMenu}
                color='inherit'
                sx={{ padding: '0' }}
                disableRipple
              >
                <MobileMenuIcon isOpen={showMobileMenu} />
              </IconButton>
            )}
          </Toolbar>
          {showUserMenu && (
            <UserMenuMobile loading={loading} {...commonProps} />
          )}
        </Container>
      </AppBar>
      <ModalOverlayBg isActive={showMobileMenu} />
    </Box>
  );
}

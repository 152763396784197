import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';

interface ILoginCard {
  headlineText: string;
  bodyText: string;
  loginHandler?: React.MouseEventHandler<HTMLDivElement>;
  eulaUrl: string;
}
const LoginCard = ({
  headlineText,
  bodyText,
  loginHandler,
  eulaUrl,
}: ILoginCard) => {
  const style: SxProps = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '10px',
    background: 'rgba(4, 8, 9, 0.02)',
    border: '1px solid rgba(4, 8, 9, 0.08)',
    borderRadius: '8px',
    width: '218.5px',
    height: '200px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(81, 219, 205, 0.13)',
    },
  };

  return (
    <Box>
      <Box sx={style} onClick={loginHandler}>
        <Typography fontWeight='600' lineHeight='24px'>
          {headlineText}
        </Typography>
        <Typography>{bodyText}</Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          padding: '6px',
          display: 'inline',
          bottom: '40px',
          left: '10px',
        }}
      >
        <Typography
          variant='caption'
          sx={{
            a: {
              color: 'rgba(0, 0, 0, 0.84)',
              fontSize: '12px',
            },
          }}
        >
          <a rel='noreferrer' target='_blank' href={eulaUrl}>
            Terms and conditions
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginCard;

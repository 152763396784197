/* eslint-disable no-debugger */
import React, { FC } from 'react';
import SelectorBody from './components/selector-body.component';
import SorensonLogin from 'shared/assets/images/sorenson-login-new.png';
import SelectorHeader from './components/selector-header.component';
import Navbar from 'shared/components/navbar/navbar.component';
import CardContainer from 'shared/components/card/card.component';
import Page from 'shared/components/page/page.component';
// import { useNavigate } from 'react-router-dom';

const LoginSelectorPage: FC = () => {
  // const navigate = useNavigate();

  // React.useEffect(() => {
  //   navigate('vri');
  // }, []);

  // if (process.env.REACT_APP_VRI_ONLY === 'true') {
  // debugger;
  // navigate('vri');
  // return null;
  // } else {
  return (
    <Page bgImage={SorensonLogin} title='Sign In Selector'>
      <Navbar showUserMenu={false} />
      <CardContainer>
        <SelectorHeader />
        <SelectorBody />
      </CardContainer>
    </Page>
  );
};
// };
export default LoginSelectorPage;

import * as React from 'react';
import { Avatar, IconButton, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LAYOUT_BG_COLOR } from 'shared/styles/colors';
import PhoneNumberFormat from './phone-number-format';
import { removeCountryCode } from 'shared/services/format-phone-number';
import { UserType } from 'shared/constants';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';

interface Props {
  sorensonUser: ISorensonUser;
  isActive?: boolean;
  handleOpenUserMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  loading: boolean;
}

export default function AvatarNavbar({
  sorensonUser,
  isActive = false,
  handleOpenUserMenu,
  loading,
}: Props) {
  const { name, phoneNumber, userType, email } = sorensonUser;

  const stringAvatar = (name: string) => {
    return name
      .split(' ')
      .map((word) => word.substring(0, 1))
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };

  return (
    <Box
      sx={{
        display: {
          xs: `${isActive ? 'flex' : 'none'}`,
          md: 'flex',
        },
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          textAlign: { xs: 'center', md: 'right' },
          marginRight: '1rem',
          display: { md: 'block' },
          margin: { xs: '6px 0 24px 0', md: '25px 16px 19px 0px' },
          color: 'background.default',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>
          {loading ? (
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={250}
              height={16}
              sx={{
                bgcolor: 'grey.500',
                marginBottom: '8px',
              }}
            />
          ) : userType === UserType.VRI ? (
            name
          ) : null}
        </Typography>
        {loading ? (
          <Typography variant='body1'>
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={250}
              height={16}
              sx={{
                bgcolor: 'grey.500',
              }}
            />
          </Typography>
        ) : userType === UserType.VRI ? (
          <Typography variant='body1'>{email}</Typography>
        ) : (
          <PhoneNumberFormat phone={removeCountryCode(`${phoneNumber}`)} />
        )}
      </Box>
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{ p: 0, display: { md: 'flex' } }}
      >
        {loading ? (
          <Skeleton
            variant='circular'
            sx={{
              bgcolor: 'grey.500',
            }}
          >
            <Avatar
              sx={{
                height: { xs: '64px', md: '56px' },
                width: { xs: '64px', md: '56px' },
              }}
            />
          </Skeleton>
        ) : (
          <Avatar
            sx={{
              height: { xs: '64px', md: '56px' },
              width: { xs: '64px', md: '56px' },
              bgcolor: 'grey.500',
            }}
            src={
              userType === UserType.VRS
                ? 'assets/images/sorenson-vrs-avatar.svg'
                : ''
            }
          >
            {stringAvatar(name)}
          </Avatar>
        )}
        {loading ? (
          <Skeleton
            width={24}
            height={24}
            sx={{
              bgcolor: 'grey.500',
              marginLeft: '8px',
            }}
          />
        ) : (
          <ExpandMoreIcon
            sx={{
              marginLeft: '8px',
              color: LAYOUT_BG_COLOR,
              display: { xs: 'none', md: 'flex' },
            }}
          />
        )}
      </IconButton>
    </Box>
  );
}

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import SorensonLogoNavbar from '../components/logo.component';
import AvatarNavbar from '../components/avatar.component';
import UserMenu from './user-menu-desktop.component';
import ISorensonUser from 'shared/interfaces/sorenson-user.interface';

interface Props {
  sorensonUser: ISorensonUser;
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElUser: HTMLElement | null;
  handleCloseUserMenu: () => void;
  signOut: () => void;
  showUserMenu?: boolean;
  loading: boolean;
}

export default function DesktopNavBar({
  sorensonUser,
  handleOpenUserMenu,
  anchorElUser,
  handleCloseUserMenu,
  signOut,
  showUserMenu = true,
  loading,
}: Props) {
  return (
    <AppBar color='secondary'>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          position: 'relative',
          height: '100px',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            height: '100%',
            padding: '0 42px 0 73px',
          }}
        >
          <SorensonLogoNavbar />
          {showUserMenu && (
            <>
              <AvatarNavbar
                sorensonUser={sorensonUser}
                handleOpenUserMenu={handleOpenUserMenu}
                loading={loading}
              />
              <UserMenu
                anchorElUser={anchorElUser}
                handleCloseUserMenu={handleCloseUserMenu}
                signOut={signOut}
              />
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

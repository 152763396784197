import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingButton from 'shared/components/loading-button/loading-button.component';
import reloadCurrentPage from 'shared/functions/reloadCurrentPage';

interface Props {
  teamsSupport: boolean;
}

function UnavailableRequestForm({ teamsSupport }: Props) {
  return (
    <Box>
      <Typography variant='h2' sx={{ marginBottom: '16px' }}>
        You can still get an interpreter in your Zoom{' '}
        <span style={{ display: `${teamsSupport ? 'inline-block' : 'none'}` }}>
          or Teams
        </span>{' '}
        meeting
      </Typography>
      <Typography variant='subtitle1' sx={{ marginBottom: '14px' }}>
        <ul style={{ listStyle: 'none', padding: '0px' }}>
          <li>- Connect to Sorenson VRS </li>
          <li>- Give the interpreter the Zoom audio number for your meeting</li>
          <li>- Both you and the interpreter can join your Zoom meeting</li>
        </ul>
      </Typography>
      <LoadingButton onClick={reloadCurrentPage}>Try Again</LoadingButton>
    </Box>
  );
}

export default UnavailableRequestForm;

import * as React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format?: string;
}

const MaskedNumber = React.forwardRef<NumberFormat<string>, CustomProps>(
  function MaskedNumber(props, ref) {
    const { onChange, format, ...other } = props;
    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={format || '1 (###) ###-####'}
        isNumericString
      />
    );
  }
);

export default MaskedNumber;

export const getLocalValue = <T>(key: string): T | null => {
  const JSONValue = JSON.parse(localStorage.getItem(key) as string);
  return JSONValue ? (JSON.parse(JSONValue) as T) : null;
};

export const setLocalItem = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalItem = (key: string): void => {
  localStorage.removeItem(key);
};

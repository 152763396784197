import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  isActive: boolean;
};

export default function ModalOverlayBg({ isActive }: Props) {
  return isActive ? (
    <Box
      sx={{
        position: 'relative',
        top: '0%',
        left: '0%',
        bgcolor: '#24292e',
        height: '100%',
        opacity: ' 0.76',
        zIndex: '99',
      }}
    />
  ) : null;
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';
import ReactMarkdown from 'react-markdown';

interface Props {
  instructions: Array<string>;
}

function BoxContent({ instructions }: Props) {
  const bodyItems = instructions.map((item, idx) => {
    return (
      <ListItem disablePadding key={idx}>
        <Typography
          variant='subtitle1'
          sx={{ letterSpacing: '-0.34px', lineHeight: 'normal' }}
        >
          <ReactMarkdown>{`${++idx}- ${item}`}</ReactMarkdown>
        </Typography>
      </ListItem>
    );
  });

  return (
    <List
      disablePadding
      sx={{
        padding: '0 16px',
      }}
    >
      {bodyItems}
    </List>
  );
}

export default BoxContent;

import { useState, createContext, ReactElement, useContext } from 'react';

interface IAlertProvider {
  children: ReactElement;
}

interface IAlert {
  type: 'error' | 'info' | 'warning' | 'success';
  message: string;
  id: string;
}

export interface IuseAlert {
  showAlert: (
    type: 'error' | 'info' | 'warning' | 'success',
    message: string
  ) => string;
  alerts: IAlert[];
  closeAlert: (id: string) => void;
}

export const AlertContext = createContext({} as IuseAlert);

export function AlertProvider({ children }: IAlertProvider) {
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  const closeAlert = (alertId: string) => {
    const filteredAlerts = alerts.filter(({ id }) => {
      return id !== alertId;
    });

    setAlerts(filteredAlerts);
  };

  const showAlert = (
    alertType: 'error' | 'info' | 'warning' | 'success',
    alertMessage: string
  ) => {
    const randomId = Math.floor(Math.random() * 1000).toString();

    setAlerts([
      ...alerts,
      { type: alertType, message: alertMessage, id: randomId },
    ]);

    if (alertType === 'success') {
      setTimeout(() => closeAlert(randomId), 5000);
    }

    return randomId;
  };

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert, alerts }}>
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = () => {
  return useContext(AlertContext);
};

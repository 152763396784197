import { Box } from '@mui/material';
import SorensonLogo from 'shared/assets/icons/sorenson-logo-navbar.png';

export default function SorensonLogoNavbar() {
  return (
    <Box
      sx={{
        maxHeight: { xs: '18px', md: '30px' },
        maxWidth: { xs: '137px', md: '200px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        alt='Sorenson Logo'
        src={SorensonLogo}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </Box>
  );
}

import { styled } from '@mui/material/styles';

import LB from '@mui/lab/LoadingButton';

const LoadingButton = styled(LB)(({ theme }) => ({
  '&': {
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderRadius: '6px',
    height: '48px',
    fontSize: '16px',
    width: '100%',
    textTransform: 'unset !important',
  },
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));
export default LoadingButton;

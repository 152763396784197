import React, { FC, ReactNode } from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box } from '@mui/material';
import { ERROR_BG_COLOR, ERROR_BORDER } from 'shared/styles/colors';

type IErrorMessage = {
  children: ReactNode;
};

const ErrorMessage: FC<IErrorMessage> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '6px',
        backgroundColor: ERROR_BG_COLOR,
        alignItems: 'flex-start',
        borderColor: ERROR_BORDER,
        borderStyle: 'solid',
        borderWidth: 'thin',
        padding: '12px',
        gap: '12px',
      }}
    >
      <ErrorOutlineRoundedIcon style={{ color: ERROR_BORDER }} />
      {children}
    </Box>
  );
};

export default ErrorMessage;

import React, { FC } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import useTitle from 'shared/hooks/use-title';

const MotionContainer = motion(Container);

type IPage = {
  children: React.ReactNode;
  bgImage: string;
  title: string;
  animate?: boolean;
};

const Page: FC<IPage> = ({ children, bgImage, title, animate = true }) => {
  useTitle(title);
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const smViewPort = useMediaQuery((theme: any) => theme.breakpoints.down(330));
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: smViewPort ? 'start' : 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        height: 'fit-content',
        padding: '24px',
        background: `${
          matches ? `url(${bgImage})` : '#ffffff'
        }  no-repeat right`,
      }}
      maxWidth={false}
      className='container'
    >
      {children}
    </Container>
  );
};

export default Page;
